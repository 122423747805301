/* Standard width for content area */
@use "../variables" as *;

.content-width {
  padding-left: $side-padding !important;
  padding-right: $side-padding !important;
  max-width: $content-width;
}

.align-right {
    text-align: right;
}

.logo {
  img{
    height: $bar-height;
    width: auto;
  }
}
