.caption {
  font-style: italic;
  font-size: smallest;
  float: right;
}
ul.figure {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin: 10px 5px 10px 0px;
  width: auto;
  height: auto;
  float: left;
  list-style-type: none;
}

#content-container ul.figure li {
//   margin: 0px;
}

ul.figure li {
  list-style-type: none;
  float: left;
  font-size: smaller;
  font-style: italic;
  width: 100%;
  height: auto;
  margin: 0px;
  margin-left: 0px;
}

ul.figure li img {
  width: auto;
  height: auto;
}

/* ul, li, ol { */
/*     float:left; */
/*     list-style-type:none; */
/* } */

/* ul li{ */
/*     float:left; */
/*     clear: both; */
/*     height:auto; */
/*     width:auto; */
/* } */
