@forward "variables"; // Reference to _sass/_variables.scss
@forward "base";
@forward "flexslider";
@forward "foundation";
@forward "archtek";
@forward "isotope";
@forward "mauc";
@forward "font";

body {
  font-family: 'ShreeDev0714', sans-serif;
}
