$color-foreground: #000;
$color-foreground-light: #333;
$color-foreground-footer: #333;
$color-background: #fff;
$color-background-light: #f5f5f5;
$color-background-footer: #f5f5f5;

// $color-primary: rgb(27, 131, 190);
$color-primary: rgb(251, 164, 24);
$color-primary-light: #ffc074;

$font-primary: 'ShreeDev0714', sans-serif;
$font-secondary: 'Arial', sans-serif;

$background-primary: $color-background;;
$background-secondary: rgba($color-background,0.9);
$background-footer: rgba($color-background,0.9);
// $background-secondary: url(/images/concrete_wall_3.png) top left repeat;
// $background-footer: url(/images/classy_fabric.png) top left repeat;

// url(/images/concrete_wall_3.png) top left repeat;
//   image: url('../images/bg.jpg');
//   position: center;
//   size: cover;

$background-button: $color-primary;
$background-button-light: $color-primary-light;
$bar-height:3rem;



$content-width: 1020px;
$header-max-height: 8rem;
$side-padding: 2rem;