@font-face {
    font-family: 'ShreeDev0714';
    src: url("/assets/font/ShreeDev0714.eot");
    src: url("/assets/font/ShreeDev0714.eot?#iefix") format("embedded-opentype"),
         url("/assets/font/ShreeDev0714.woff2") format("woff2"),
         url("/assets/font/ShreeDev0714.woff") format("woff"),
         url("/assets/font/ShreeDev0714.ttf") format("truetype"),
         url("/assets/font/ShreeDev0714.svg#ShreeDev0714") format("svg");
    font-weight: normal;
    font-style: normal;
  }